import React, {
  useState,
  useEffect,
  useRef,
} from "react";
import "./Search.css";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || "";

function Search(): JSX.Element {
  const [ean, setEan] = useState<string>("");
  const [searchResult, setSearchResult] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [showBoxForm, setShowBoxForm] = useState<boolean>(false);
  const [boxNumber, setBoxNumber] = useState<string>("");

  const [searchHistory, setSearchHistory] = useState<string[]>([]);

  const inputRef = useRef<HTMLInputElement>(null);
  const boxInputRef = useRef<HTMLInputElement>(null);

  const focusInput = (ref: React.RefObject<HTMLInputElement>) => {
    setTimeout(() => {
      ref.current?.focus();
    }, 100);
  };

  const handleSearch = async (ean: string): Promise<void> => {
    if (ean.length < 5) {
      setEan("");
      return;
    }

    try {
      setLoading(true);
      const response = await fetch(`${API_BASE_URL}/what-box`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ ean }),
      });

      const data = await response.json();
      if (data.box) {
        setSearchResult(`✅ Use Box `);
        if (data.boxModel) {
          setSearchResult(`✅ Use Box ${data.box} (${data.boxModel.name})`);
        }
        
        setShowBoxForm(false);
        setEan("");
        focusInput(inputRef);
        setSearchHistory((prevHistory) => [`${new Date().toLocaleTimeString()} - ${ean} - ${data.box}` || "", ...prevHistory]);
      } else {
        setSearchResult(`🚫 Could not find box for ${ean}`);
        setShowBoxForm(true);
        setBoxNumber("");
        focusInput(boxInputRef);
      }
    } catch (error) {
      setSearchResult("⚠️ Something went wrong, please try again.");
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleBoxSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();

    if (boxNumber.length === 0) {
      return;
    }

    if (boxNumber.length > 5) {
      handleBack();
      focusInput(inputRef);
      return;
    }

    try {
      setLoading(true);
      const response = await fetch(`${API_BASE_URL}/update-box`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ ean, box: boxNumber }),
      });

      if (response.ok) {
        setSearchResult(`Use box ${boxNumber} for ${ean}`);
        console.log("Box updated successfully!");
        focusInput(inputRef);
        setSearchHistory((prevHistory) => [`🔗 ${new Date().toLocaleTimeString()} - ${ean} - ${boxNumber}` || "", ...prevHistory]);
      } else {
        setSearchResult("Failed to update box.");
      }
    } catch (error) {
      setSearchResult("Something went wrong, please try again.");
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
    setShowBoxForm(false);
    setBoxNumber("");
    setEan("");
  };

  const handleBack = (): void => {
    setShowBoxForm(!showBoxForm);
    setBoxNumber("");
    setSearchResult(null);
    setEan("");
  };

  const handleDeleteHistory = async (historyEntry: string): Promise<void> => {
    // Use a regular expression to match and extract only digits (EAN) from the history entry
    const match = historyEntry.match(/ - (\d+) - /);
    if (!match) {
      console.error("Invalid history entry format:", historyEntry);
      return;
    }
  
    const eanToDelete = match[1]; // Extract the matched EAN
  
    const confirmDelete = window.confirm(`Are you sure you want to delete the history entry for EAN: ${eanToDelete}?`);
  
    if (!confirmDelete) {
      return;
    }
  
    try {
      setLoading(true);
      const response = await fetch(`${API_BASE_URL}/unlink-box`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ ean: eanToDelete }),
      });
  
      if (response.ok) {
        setSearchHistory((prevHistory) => prevHistory.filter((entry) => entry !== historyEntry));
        setShowBoxForm(false);
        setBoxNumber("");
        setSearchResult(null);
        setEan("");
        console.log(`History entry for EAN: ${eanToDelete} deleted successfully.`);
      } else {
        setSearchResult("Failed to delete history entry.");
      }
    } catch (error) {
      setSearchResult("Something went wrong, please try again.");
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };
  
  useEffect(() => {
    focusInput(inputRef);
  }, []);

  return (
    <div className="Search">
      {loading && <div className="loader">Loading...</div>}
      {!loading && !showBoxForm && (
        <div>
          <header className="Search Header">
            <div className="SearchResult">
              {searchResult && <label className="StatusLabel">{searchResult}</label>}
            </div>
            <input
              type="text"
              value={ean}
              onChange={(e) => setEan(e.target.value)}
              onKeyPress={(e) => e.key === "Enter" && handleSearch(ean)}
              placeholder="Scan or enter barcode"
              ref={inputRef}
            />
            <button onClick={() => handleSearch(ean)}>Search</button>
          </header>
          <div className="SearchHistory">
            {searchHistory.slice(0, 10).map((historyItem, index) => (
              <div key={index} className="HistoryItem">
                {historyItem}
                <button className="DeleteButton" onClick={() => handleDeleteHistory(historyItem)}>❌</button>
              </div>
            ))}
          </div>
        </div>
      )}
      {!loading && showBoxForm && (
        <div className="BoxForm">
          <form onSubmit={handleBoxSubmit}>
            <div>
              {searchResult && <label className="StatusLabel">{searchResult}</label>}
            </div>
            <input
              type="text"
              value={boxNumber}
              onChange={(e) => setBoxNumber(e.target.value)}
              placeholder="Enter box number"
              ref={boxInputRef}
            />
            <button type="submit">Submit</button>
            <button onClick={handleBack}>Back</button>
          </form>
        </div>
      )}
    </div>
  );
}

export default Search;
